import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { Box, FormControlLabel, FormGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { AdvancedRow } from "../types";

function IgnoreConversionSelection({
  item,
  handleMappingChange,
  className,
}: {
  item: AdvancedRow;
  handleMappingChange: any;
  className?: string;
}) {
  const handleOptionChange = (event: any, newValue: any[]) => {
    // Filter out duplicates
    const uniqueValues = newValue
      .map((option) => option.toUpperCase())
      .filter((option, index, self) => {
        return self.indexOf(option) === index;
      });

    handleMappingChange(
      {
        target: {
          name: "ignoreConversion.valuesToIgnore",
          value: uniqueValues,
        },
      },
      item.id
    );
  };

  return (
    <Box className={className}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={item.ignoreConversion?.allowNulls ?? false}
              onChange={(e) => {
                handleMappingChange(
                  {
                    target: {
                      name: "ignoreConversion.allowNulls",
                      value: e.target.checked,
                    },
                  },
                  item.id,
                  item.selectedRule
                );
              }}
            />
          }
          label="Allow null"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={item.ignoreConversion?.allowEmpty ?? false}
              onChange={(e) => {
                handleMappingChange(
                  {
                    target: {
                      name: "ignoreConversion.allowEmpty",
                      value: e.target.checked,
                    },
                  },
                  item.id,
                  item.selectedRule
                );
              }}
            />
          }
          label="Allow empty strings"
        />
      </FormGroup>
      <Autocomplete
        freeSolo
        multiple
        options={[]} // Starts with empty options
        value={item.ignoreConversion?.valuesToIgnore ?? []}
        onChange={handleOptionChange}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...otherProps } = getTagProps({ index });
            return (
              <Chip
                key={key}
                variant="outlined"
                label={option}
                {...otherProps}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Enter values to ignore"
            variant="outlined"
          />
        )}
      />
    </Box>
  );
}

export default IgnoreConversionSelection;
