export interface ExcelSheet {
  sourceSheetName: string;
  locationPattern: string;
  locationKey: string;
  id: string;
  valid: boolean;
  headerRow?: number;
}

export interface MappingRow {
  excelSheet: ExcelSheet;
  column: string;
  editMode: boolean;
  mappedCol: MappingCol;
  mappedColDateFormat: string | null;
  naOverride: boolean;
  id: string;
  valid: boolean;
}

export interface AdvancedRow {
  selectedRule: RuleType;
  id: string;
  editMode: boolean;
  valid: boolean;
  column: string;
  multipleColumns: string[] | null;
  mappedColDateFormat: string | null;
  mappedCol: MappingCol;
  excelSheet: ExcelSheet;
  hardcoded?: {
    value: string;
  };
  concat?: {
    separator: string;
  };
  coalesce?: {
    nullStrings?: string[];
  };
  ignoreConversion?: {
    allowNulls: boolean;
    allowEmpty: boolean;
    valuesToIgnore: string[];
  };
}

export interface MappingCol {
  name: string;
  type: string;
  reason?: string;
  disabled?: boolean;
}

export enum RuleType {
  Concatenate = "Concatenate",
  Coalesce = "Coalesce",
  Hardcoded = "Hardcoded",
  IgnoreConversion = "IgnoreConversion",
  Unknown = "Unknown",
}
