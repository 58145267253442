import { AdvancedRow, RuleType } from "../components/types";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import { MappingCol } from "../hooks/useMappingEngineSchema";

export enum advActions {
  add_adv_row = "add_adv_row",
  update_adv_row = "update_adv_row",
  delete_adv_row = "delete_adv_row",
  populate_adv_row = "populate_adv_row",
}

const isValidHardcoded = (item: AdvancedRow): boolean => {
  return !!(item.hardcoded?.value && item.mappedCol?.name);
};

const isValidCoalesceConcat = (item: AdvancedRow): boolean => {
  return !!(
    item.mappedCol?.name &&
    item.multipleColumns &&
    item.multipleColumns.length > 0 &&
    item.excelSheet?.valid &&
    item.excelSheet.sourceSheetName
  );
};

const isValidIgnoreConversion = (item: AdvancedRow): boolean => {
  if (
    item.mappedCol?.name &&
    item.column &&
    item.excelSheet?.valid &&
    item.excelSheet.sourceSheetName
  ) {
    const hasIgnoreConditions =
      item.ignoreConversion?.allowEmpty ||
      item.ignoreConversion?.allowNulls ||
      (item.ignoreConversion?.valuesToIgnore?.length ?? 0) > 0;

    if (item.mappedCol.type === "date") {
      return !!item.mappedColDateFormat && hasIgnoreConditions;
    }

    return hasIgnoreConditions;
  }
  return false;
};

const validateAdvRows = (item: AdvancedRow) => {
  let isValid: boolean = false;

  switch (item.selectedRule) {
    case RuleType.Hardcoded:
      isValid = isValidHardcoded(item);
      break;
    case RuleType.Concatenate:
      isValid = isValidCoalesceConcat(item);
      break;
    case RuleType.Coalesce:
      isValid = isValidCoalesceConcat(item);
      break;
    case RuleType.IgnoreConversion:
      isValid = isValidIgnoreConversion(item);
      break;
  }

  return { ...item, valid: isValid };
};

export function advancedReducer(state: AdvancedRow[], action: any) {
  switch (action.type) {
    case advActions.populate_adv_row: {
      const newMappingCol: MappingCol = {
        name: action.mappingColName,
        type: action.mappingColType,
        required: false,
        disabled: false,
        reason: "",
      };

      const newMapping: AdvancedRow = {
        selectedRule: action.selectedRule,
        excelSheet: {
          sourceSheetName: action.excelSourceSheetName,
          locationPattern: action.excelSheetPattern,
          locationKey: action.excelSheetLocationKey,
          id: action.excelSheetId,
          valid: true,
        },
        hardcoded: {
          value: action?.hardcoded?.value,
        },
        coalesce: {
          nullStrings: action?.coalesce?.nullStrings,
        },
        concat: {
          separator: action?.concat?.separator,
        },
        ignoreConversion: {
          allowEmpty: action?.ignoreConversion?.allowEmpty,
          allowNulls: action?.ignoreConversion?.allowNulls,
          valuesToIgnore: action?.ignoreConversion?.valuesToIgnore,
        },
        editMode: false,
        column: action.column,
        multipleColumns: action.multipleColumns,
        mappedCol: newMappingCol,
        mappedColDateFormat: action.sourceColDateFormat,
        id: uuidv4(),
        valid: true,
      };
      return [...state, newMapping];
    }
    case advActions.add_adv_row: {
      const newMappingCol: MappingCol = {
        name: "",
        type: "string",
        required: false,
        disabled: false,
        reason: "",
      };

      const newItem: AdvancedRow = {
        selectedRule: RuleType.Unknown,
        id: uuidv4(),
        excelSheet: {
          sourceSheetName: "",
          locationPattern: "",
          locationKey: "",
          id: "",
          valid: false,
        },
        column: "",
        multipleColumns: null,
        editMode: true,
        valid: false,
        mappedCol: newMappingCol,
        mappedColDateFormat: "",
      };
      return [...state, newItem];
    }
    case advActions.update_adv_row: {
      return state.map((t: AdvancedRow) => {
        if (t.id === action.id) {
          let updatedObject = { ...t };

          const updateNestedProperty = (obj: any, path: string, value: any) => {
            const keys = path.split(".");
            const lastKey = keys.pop();
            const lastObj = keys.reduce(
              (obj, key) => (obj[key] = obj[key] || {}),
              obj
            );
            if (lastKey) lastObj[lastKey] = value;
          };

          if (action.name.includes(".")) {
            updateNestedProperty(updatedObject, action.name, action.value);
          } else {
            updatedObject = {
              ...updatedObject,
              [action.name]: action.value,
            };
          }

          if (t.selectedRule !== updatedObject.selectedRule) {
            switch (action.selectedRule) {
              case RuleType.Hardcoded:
                updatedObject = {
                  ...updatedObject,
                  hardcoded: {
                    value: "",
                  },
                  valid: false,
                };
                break;
              case RuleType.Concatenate:
                updatedObject = {
                  ...updatedObject,
                  concat: {
                    separator: ", ",
                  },
                  valid: false,
                };
                break;
              case RuleType.Coalesce:
                updatedObject = {
                  ...updatedObject,
                  coalesce: {
                    nullStrings: [],
                  },
                  valid: false,
                };
                break;
              case RuleType.IgnoreConversion:
                updatedObject = {
                  ...updatedObject,
                  ignoreConversion: {
                    allowEmpty: false,
                    allowNulls: false,
                    valuesToIgnore: [],
                  },
                  valid: false,
                };
                break;
            }
          }
          return validateAdvRows(updatedObject);
        } else {
          return t;
        }
      });
    }
    case advActions.delete_adv_row: {
      return state.filter((item: { id: string }) => item.id !== action.id);
    }
    default: {
      return state;
    }
  }
}
