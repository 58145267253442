import React, { useState } from "react";
import SHIFilePond from "shared-ui/src/components/SHIFilePond";
import { TextField } from "@mui/material";

// @ts-ignore
function CommaSeparatedInput({ setManualHeaderAssignment }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setInputValue(value);

    // Validate the input
    const isValid = validateInput(value);
    setErrorMessage(
      isValid
        ? ""
        : "Please enter numbers between -1 and 100, separated by commas."
    );
    if (isValid) {
      setManualHeaderAssignment(value);
    }
  };

  const validateInput = (value: string) => {
    // Split input by commas and trim whitespace
    const numbers = value.split(",").map((num) => num.trim());

    // Check if all numbers are valid integers within the range
    return numbers.every((num) => {
      const parsed = parseInt(num, 10);
      return !isNaN(parsed) && parsed >= -1 && parsed <= 100;
    });
  };

  return (
    <div>
      <TextField
        label="Manually Map Headers? (Use -1 to skip sheet and 0 for first row; separate by comma)"
        variant="outlined"
        fullWidth
        value={inputValue}
        onChange={handleChange}
        error={!!errorMessage}
        helperText={errorMessage}
      />
    </div>
  );
}

export default function FileUpload({
  files,
  setFiles,
  setUploadResponse,
  token,
}: {
  files: any;
  setFiles: any;
  setUploadResponse: any;
  token: string;
}) {
  const [manualHeaderAssignment, setManualHeaderAssignment] = useState<
    number[] | string
  >("");

  const filePondServer = {
    server: {
      url: `${import.meta.env.API_ENDPOINT}/api/MappingEngine`,
      timeout: 230000,
      process: {
        method: "POST" as const,
        withCredentials: false,
        timeout: 230000,
        onload: (response: string) => {
          const jsonResponse = JSON.parse(response);
          setUploadResponse(jsonResponse);
          return jsonResponse.guid;
        },
        onerror: (response: { data: any }) => response.data,
        ondata: (formData: any) => {
          if (manualHeaderAssignment !== "") {
            formData.append("manualHeadersAssignment", manualHeaderAssignment);
          }
          return formData;
        },
        headers: { Authorization: "Bearer " + token },
        revert: { url: "", headers: { Authorization: "Bearer " + token } },
      },
    },
  };

  return (
    <div className="mb-8 mt-4">
      <div className={"mb-4"}>
        <CommaSeparatedInput
          setManualHeaderAssignment={setManualHeaderAssignment}
        />
      </div>

      <SHIFilePond
        server={filePondServer.server}
        setFiles={setFiles}
        files={files}
      />
    </div>
  );
}
