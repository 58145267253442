import { useQuery } from "@tanstack/react-query";

export interface MappingCol {
  name: string;
  type: string;
  required: boolean;
  disabled: boolean;
  reason: string;
}

export interface IMappingSchema {
  columns: MappingCol[];
}

export default function useMappingEngineSchema({ url }: { url: string }) {
  const fetchSchema = async () => {
    if (url !== "") {
      const result = await fetch(url);
      return await result.json();
    }
    return {};
  };

  return useQuery<IMappingSchema>({
    queryKey: ["mapping-schema", url],
    queryFn: fetchSchema,
  });
}
