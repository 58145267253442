import React, { memo } from "react";
import { AdvancedRow, MappingRow } from "../types";
import { MenuItem, Select } from "@mui/material";

export const MemoExcelSheetSelect = memo(function ExcelSheetSelect(props: {
  item: MappingRow | AdvancedRow;
  onChange: (e: any) => void;
  renderValue: (item: any) => any;
  excelSheets: any;
  element: (excelSheet: any) => React.JSX.Element;
  className?: string;
}) {
  return (
    <Select
      className={props?.className}
      fullWidth
      displayEmpty
      variant="outlined"
      disabled={"naOverride" in props.item ? props.item.naOverride : false}
      onChange={props.onChange}
      name="excelSheet"
      value={props.item.excelSheet ?? ""}
      renderValue={props.renderValue}
    >
      <MenuItem value="" disabled>
        Select a Sheet
      </MenuItem>
      {props.excelSheets.map(props.element)}
    </Select>
  );
});
