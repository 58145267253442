import React, { memo, useCallback } from "react";
import { advActions } from "../reducers/advancedReducer";
import {
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AdvancedRow, RuleType } from "./types";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  EditOff,
  ErrorOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";
import MemoMappedAutoComplete from "./buildables/MappedColAutoComplete";
import { MemoExcelSheetSelect } from "./buildables/MemoExcelSheetSelect";
import MemoExcelColumnAutoComplete from "./buildables/ExcelColumnAutoComplete";
import IgnoreConversionSelection from "./buildables/IgnoreConversionSelection";
import DateFormatAutocomplete from "./buildables/DateFormatAutoComplete";

const MemoRowDelete = memo(function RowDelete(props: {
  editMode: boolean;
  onClick: () => any;
}) {
  return (
    <div className={"ml-auto"}>
      {props.editMode && (
        <IconButton color="error" onClick={props.onClick}>
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  );
});

export default function AdvRule({
  mapping,
  dispatch,
  data,
  state,
  uploadResponse,
}: {
  mapping: AdvancedRow;
  dispatch: any;
  data: any;
  state: any;
  uploadResponse: any;
}) {
  const handleMappingChange = useCallback(
    (
      e: { target: { name: string; value: any } },
      id: string,
      selectedRule: RuleType
    ) => {
      dispatch({
        type: advActions.update_adv_row,
        selectedRule: selectedRule,
        name: e.target.name,
        value: e.target.value,
        id,
      });
    },
    []
  );

  const toggleEditMode = useCallback(
    (item: { editMode: boolean; id: string }) => {
      dispatch({
        type: advActions.update_adv_row,
        name: "editMode",
        value: !item.editMode,
        id: item.id,
      });
    },
    []
  );

  const rules = Object.keys(RuleType).filter((key) => key !== RuleType.Unknown);

  return (
    <Card className="flex grow pb-6 gap-3 w-full p-4 mb-4">
      <div>
        <div className={"flex items-center gap-2"}>
          <IconButton
            onClick={() => toggleEditMode(mapping)}
            color={"primary"}
            size={"small"}
          >
            {mapping.editMode ? <EditOff /> : <EditIcon />}
          </IconButton>
          {mapping.valid ? (
            <VerifiedOutlined color="success" />
          ) : (
            <ErrorOutlined color="error" />
          )}
          <div className={"w-52"}>
            <Select
              fullWidth
              variant={"outlined"}
              value={mapping.selectedRule}
              onChange={(event) =>
                handleMappingChange(event, mapping.id, mapping.selectedRule)
              }
              name={"selectedRule"}
            >
              <MenuItem value={RuleType.Unknown}>Select an Rule</MenuItem>
              {rules.map((i) => (
                <MenuItem value={i} key={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
      {mapping.selectedRule === RuleType.Hardcoded && (
        <div className={"flex grow justify-between gap-4"}>
          <Card className={"grow p-4"}>
            <TextField
              label={"Enter Value"}
              fullWidth
              className={"mb-4"}
              name={"hardcoded.value"}
              value={mapping?.hardcoded?.value ?? ""}
              onChange={(e) =>
                handleMappingChange(e, mapping.id, mapping.selectedRule)
              }
            />
            <MemoMappedAutoComplete
              mapping={mapping}
              data={data}
              handleMappingChange={handleMappingChange}
              state={state}
              selectedRule={mapping.selectedRule}
            />
          </Card>
        </div>
      )}
      {mapping.selectedRule === RuleType.Concatenate && (
        <div className={"flex grow justify-between gap-4"}>
          <Card className={"grow p-4 gap-4"}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mapping?.concat?.separator === "; "}
                  onChange={(e) => {
                    const sep = e.target.checked ? "; " : ", ";
                    handleMappingChange(
                      { target: { name: "concat.separator", value: sep } },
                      mapping.id,
                      mapping.selectedRule
                    );
                  }}
                  name="concat.separator"
                  color="primary"
                />
              }
              label="Use '; ' as separator"
            />
            <Typography className={"mb-4"}>
              Current separator: {mapping.concat?.separator}
            </Typography>
            <MemoExcelSheetSelect
              className={"mb-4"}
              item={mapping}
              onChange={(e) =>
                handleMappingChange(e, mapping.id, mapping.selectedRule)
              }
              renderValue={(item) => item.sourceSheetName}
              excelSheets={state.excelSheets}
              element={(excelSheet) =>
                excelSheet.valid && (
                  <MenuItem key={excelSheet.id} value={excelSheet}>
                    {excelSheet.locationKey}
                  </MenuItem>
                )
              }
            />
            {mapping.excelSheet.locationKey.length > 0 && (
              <MemoExcelColumnAutoComplete
                className={"mb-4"}
                item={mapping}
                uploadResponse={uploadResponse}
                handleMappingChange={handleMappingChange}
                allowMultiple={true}
              />
            )}
            <MemoMappedAutoComplete
              mapping={mapping}
              data={data}
              handleMappingChange={handleMappingChange}
              state={state}
              selectedRule={mapping.selectedRule}
            />
          </Card>
        </div>
      )}
      {mapping.selectedRule === RuleType.Coalesce && (
        <div className={"flex grow justify-between gap-4"}>
          <Card className={"grow p-4 gap-4"}>
            <MemoExcelSheetSelect
              className={"mb-4"}
              item={mapping}
              onChange={(e) =>
                handleMappingChange(e, mapping.id, mapping.selectedRule)
              }
              renderValue={(item) => item.sourceSheetName}
              excelSheets={state.excelSheets}
              element={(excelSheet) =>
                excelSheet.valid && (
                  <MenuItem key={excelSheet.id} value={excelSheet}>
                    {excelSheet.locationKey}
                  </MenuItem>
                )
              }
            />
            {mapping.excelSheet.locationKey.length > 0 && (
              <MemoExcelColumnAutoComplete
                className={"mb-4"}
                item={mapping}
                uploadResponse={uploadResponse}
                handleMappingChange={handleMappingChange}
                allowMultiple={true}
              />
            )}
            <MemoMappedAutoComplete
              mapping={mapping}
              data={data}
              handleMappingChange={handleMappingChange}
              state={state}
              selectedRule={mapping.selectedRule}
            />
          </Card>
        </div>
      )}
      {mapping.selectedRule === RuleType.IgnoreConversion && (
        <div className={"flex grow justify-between gap-4"}>
          <Card className={"grow p-4 gap-4"}>
            <MemoExcelSheetSelect
              className={"mb-4"}
              item={mapping}
              onChange={(e) =>
                handleMappingChange(e, mapping.id, mapping.selectedRule)
              }
              renderValue={(item) => item.sourceSheetName}
              excelSheets={state.excelSheets}
              element={(excelSheet) =>
                excelSheet.valid && (
                  <MenuItem key={excelSheet.id} value={excelSheet}>
                    {excelSheet.locationKey}
                  </MenuItem>
                )
              }
            />
            {mapping.excelSheet.locationKey.length > 0 && (
              <>
                <MemoExcelColumnAutoComplete
                  className={"mb-4"}
                  item={mapping}
                  uploadResponse={uploadResponse}
                  handleMappingChange={handleMappingChange}
                  allowMultiple={false}
                />
                <IgnoreConversionSelection
                  className={"mb-4"}
                  item={mapping}
                  handleMappingChange={handleMappingChange}
                />
              </>
            )}
            <MemoMappedAutoComplete
              mapping={mapping}
              data={data}
              handleMappingChange={handleMappingChange}
              state={state}
              selectedRule={mapping.selectedRule}
              className={"mb-4"}
            />
            {mapping?.mappedCol?.type === "date" && (
              <DateFormatAutocomplete
                value={mapping.mappedColDateFormat}
                setValue={(e: { target: { defaultValue: any } }) => {
                  handleMappingChange(
                    {
                      target: {
                        name: "mappedColDateFormat",
                        value: e.target.defaultValue,
                      },
                    },
                    mapping.id,
                    mapping.selectedRule
                  );
                }}
              />
            )}
          </Card>
        </div>
      )}
      <MemoRowDelete
        editMode={mapping.editMode}
        onClick={() => {
          dispatch({
            type: advActions.delete_adv_row,
            id: mapping.id,
          });
        }}
      />
    </Card>
  );
}
