import React, { memo, useCallback } from "react";
import { Autocomplete, MenuItem, TextField, Tooltip } from "@mui/material";
import { AdvancedRow, MappingCol, MappingRow, RuleType } from "../types";
import { IMappingSchema } from "../../hooks/useMappingEngineSchema";

interface MappingColError {
  name: string;
  reason: string;
  disabled: boolean;
}

interface IRow {
  sheetName: string;
  targetCol: string;
  selectedRule?: RuleType;
}

const defaultDisabled = [
  {
    name: "asset_id",
    reason: "PK AssetId cannot be Hardcoded",
    associatedRule: RuleType.Hardcoded,
    disabled: true,
  },
  {
    name: "asset_id",
    reason: "PK AssetId cannot be Coalesced",
    associatedRule: RuleType.Coalesce,
    disabled: true,
  },
  {
    name: "asset_id",
    reason: "PK AssetId cannot have null exceptions",
    associatedRule: RuleType.IgnoreConversion,
    disabled: true,
  },
];

const MemoMappedAutoComplete = memo(function MappedColAutocomplete({
  mapping,
  data,
  handleMappingChange,
  state,
  selectedRule,
  className,
}: {
  mapping: MappingRow | AdvancedRow;
  data: IMappingSchema;
  handleMappingChange: any;
  state: any;
  selectedRule?: RuleType;
  className?: string;
}) {
  const disabledOptionsArray = useCallback(() => {
    return (
      data?.columns
        ?.map((targetMappingCol) => {
          const disabledOptionsNames = defaultDisabled.map((item) => item.name);
          // Default Disabled Rules;
          if (selectedRule) {
            if (disabledOptionsNames.includes(targetMappingCol.name)) {
              const disabledRule = defaultDisabled.find(
                (disabledItem) =>
                  disabledItem.name === targetMappingCol.name &&
                  disabledItem.associatedRule === selectedRule
              );

              if (disabledRule?.disabled) {
                return [
                  targetMappingCol.name,
                  {
                    disable: disabledRule?.disabled,
                    reason: disabledRule?.reason,
                    name: disabledRule?.name,
                  },
                ];
              }
            }
          }

          const advancedMapping: IRow[] = state.advancedMappings.map(
            (item: AdvancedRow) => {
              return {
                sheetName: item.excelSheet.locationKey,
                targetCol: item.mappedCol?.name,
                selectedRule: item.selectedRule,
              };
            }
          );

          const basicMapping: IRow[] = state.mappings.map(
            (item: MappingRow) => {
              return {
                sheetName: item.excelSheet.locationKey,
                targetCol: item.mappedCol?.name,
              };
            }
          );

          for (const hardcodedMapping of advancedMapping) {
            if (targetMappingCol.name === hardcodedMapping.targetCol) {
              return [
                targetMappingCol.name,
                {
                  disable: true,
                  reason: `${targetMappingCol.name} already in use in advanced`,
                },
              ];
            }
          }

          const currentLocationKey = mapping?.excelSheet?.locationKey;
          // Sheet Specific Check
          if (currentLocationKey) {
            const advFiltered = advancedMapping.filter(
              (item: IRow) => item.sheetName === currentLocationKey
            );
            const inUseTargetColAdv = new Set(
              advFiltered.map((item) => item.targetCol)
            );
            if (inUseTargetColAdv.has(targetMappingCol.name)) {
              return [
                targetMappingCol.name,
                {
                  disable: true,
                  reason: `${targetMappingCol.name} already in use in advanced`,
                },
              ];
            }
            const basicFilter = basicMapping.filter(
              (item: IRow) => item.sheetName === currentLocationKey
            );
            const inUseTargetCol = new Set(
              basicFilter.map((item) => item.targetCol)
            );
            if (inUseTargetCol.has(targetMappingCol.name)) {
              return [
                targetMappingCol.name,
                {
                  disable: true,
                  reason: `${targetMappingCol.name} already in use in basic`,
                },
              ];
            }
          } else {
            // Check mappings for non-sheet specific
            const inUseTargetCol = new Set(
              basicMapping.map((item) => item.targetCol)
            );
            if (inUseTargetCol.has(targetMappingCol.name)) {
              return [
                targetMappingCol.name,
                {
                  disable: true,
                  reason: `${targetMappingCol.name} already in use in basic`,
                },
              ];
            }
          }

          return null;
        })
        .filter(Boolean) ?? []
    );
  }, [state, selectedRule, data]);

  // Convert the array of pairs into a Map
  // @ts-ignore
  const disabledOptionsMap = new Map(disabledOptionsArray());

  const options: MappingCol[] = data?.columns
    ? data?.columns?.map((item) => {
        const itemInDisabled = disabledOptionsMap.get(item.name) as
          | MappingColError
          | undefined;
        if (itemInDisabled) {
          return { ...item, reason: itemInDisabled.reason, disabled: true };
        }
        return item;
      })
    : [];

  const hideAutoComplete = () => {
    switch (selectedRule) {
      case RuleType.Hardcoded:
        return false;

      case RuleType.Coalesce:
      case RuleType.Concatenate:
        return !(
          mapping?.excelSheet.locationKey &&
          "multipleColumns" in mapping &&
          mapping.multipleColumns &&
          mapping?.multipleColumns?.length > 0
        );
      default:
        return !(mapping?.excelSheet.locationKey && mapping.column);
    }
  };

  return (
    <Autocomplete
      className={className}
      hidden={hideAutoComplete()}
      fullWidth
      options={options}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      value={mapping.mappedCol || null}
      onChange={(_event, newValue) => {
        if (newValue && "disabled" in newValue) {
          if (newValue.disabled) {
            return;
          }
        }

        handleMappingChange(
          { target: { name: "mappedCol", value: newValue } },
          mapping.id,
          selectedRule
        );
      }}
      renderOption={(props, option) => {
        const { key, ...otherProps } = props;

        return (
          <Tooltip title={option.reason ?? ""} key={key}>
            <div>
              <MenuItem
                disabled={option.disabled}
                {...otherProps}
                style={{
                  ...props.style,
                }}
              >
                {option.name}
              </MenuItem>
            </div>
          </Tooltip>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label="Select a column" variant="outlined" />
      )}
    />
  );
});

export default MemoMappedAutoComplete;
