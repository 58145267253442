import React, {
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  Alert,
  Button,
  Card,
  Collapse,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { GetBearerToken } from "../../../../../auth/BearerToken";
import { combineReducers } from "../reducers/combineReducers";
import { mappingActions, mappingReducer } from "../reducers/mappingReducer";
import {
  excelSheetActions,
  excelSheetReducer,
} from "../reducers/excelSheetReducer";
import useMappingEngineSchema, {
  IMappingSchema,
  MappingCol as TargetMappingCol,
} from "../hooks/useMappingEngineSchema";
import Row from "./Row";
import ExcelSheetRow from "./ExcelSheetRow";
import FileUpload from "./FileUpload";
import { useClaims } from "../../../../../auth/ClaimsProvider";
import JsonView from "./JsonView";
import { AdvancedRow, ExcelSheet, MappingRow, RuleType } from "./types";
import NotesIcon from "@mui/icons-material/Notes";
import {
  MappingDto,
  MappingEngineClient,
  MappingRowsResultDto,
} from "@lib/ShiOneClient";
import AddIcon from "@mui/icons-material/Add";
import { useParams } from "react-router-dom";
import { ApiClientConfig } from "../../../../../auth/ApiClientConfig";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import { useMutation } from "@tanstack/react-query";
import { ExpandLess, ExpandMore, Save } from "@mui/icons-material";
import { advActions, advancedReducer } from "../reducers/advancedReducer";
import AdvRule from "./AdvRule";

const initState = {
  mappings: [],
  excelSheets: [],
  advancedMappings: [],
};

const rootReducer = combineReducers({
  mappings: mappingReducer,
  excelSheets: excelSheetReducer,
  advancedMappings: advancedReducer,
});

const api = new MappingEngineClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export const mappingValue = (
  state: {
    [x: string]: any;
    mappings?: any;
    excelSheets?: any;
    advancedMappings?: any;
  },
  uploadResponse: MappingRowsResultDto | undefined,
  isPublished: boolean
) => {
  const validItems = state.mappings.filter(
    (item: { valid: boolean }) => item.valid
  );
  const validSheets = state.excelSheets.filter(
    (item: { valid: boolean }) => item.valid
  );
  const validAdvRules = state.advancedMappings.filter(
    (item: { valid: boolean }) => item.valid
  );

  const mappingJson = {
    status: isPublished ? "Published" : "Draft",
    fileType: uploadResponse?.fileType,
    excelSheets: validSheets.map((item: ExcelSheet) => ({
      locationPattern: item.locationPattern,
      locationKey: item.locationKey,
      sourceSheetName: item?.sourceSheetName,
      headerRow: item.headerRow,
    })),

    mappings: validItems.map((item: { [key: string]: any }) => {
      const mappingObj: { [key: string]: any } = {
        sourceColumn: item.column,
        locationKey: item.excelSheet.locationKey,
        targetColumn: item.mappedCol.name,
        targetColumnType: item.mappedCol.type,
        naOverride: item.naOverride,
        sourceSheetName: item.excelSheet.sourceSheetName,
      };

      if (item.mappedColDateFormat) {
        mappingObj.sourceColDateFormat = item.mappedColDateFormat;
      }
      return mappingObj;
    }),
    advancedMappings: validAdvRules.map((item: { [key: string]: any }) => {
      switch (item.selectedRule) {
        case RuleType.Hardcoded:
          return {
            ruleType: RuleType.Hardcoded,
            targetColumn: item.mappedCol.name,
            targetColumnType: item.mappedCol.type,
            value: item.hardcoded.value,
          };
        case RuleType.Concatenate:
          return {
            ruleType: RuleType.Concatenate,
            targetColumn: item.mappedCol.name,
            targetColumnType: item.mappedCol.type,
            separator: item?.concat?.separator ?? ", ",
            sourceSheetName: item.excelSheet.sourceSheetName,
            locationKey: item.excelSheet.locationKey,
            sourceColNames: item.multipleColumns,
          };
        case RuleType.Coalesce:
          return {
            ruleType: RuleType.Coalesce,
            targetColumn: item.mappedCol.name,
            targetColumnType: item.mappedCol.type,
            sourceSheetName: item.excelSheet.sourceSheetName,
            locationKey: item.excelSheet.locationKey,
            sourceColNames: item.multipleColumns,
          };
        case RuleType.IgnoreConversion:
          return {
            ruleType: RuleType.IgnoreConversion,
            targetColumn: item.mappedCol.name,
            targetColumnType: item.mappedCol.type,
            sourceSheetName: item.excelSheet.sourceSheetName,
            locationKey: item.excelSheet.locationKey,
            sourceColumn: item.column,
            allowNulls: item.ignoreConversion.allowNulls,
            allowEmpty: item.ignoreConversion.allowEmpty,
            valuesToIgnore: item.ignoreConversion.valuesToIgnore,
            sourceColDateFormat: item?.mappedColDateFormat,
          };
      }
    }),
  };

  return JSON.stringify(mappingJson, null, 2);
};

function handleAdvSetup(
  item: { [p: string]: any },
  newItem: {
    type: advActions;
    mappingColName: any;
    mappingColType: any;
    excelSheetLocationKey: any;
    excelSourceSheetName: any;
    excelSheetId: any;
    excelSheetPattern: any;
  }
) {
  switch (item.ruleType as RuleType) {
    case RuleType.Hardcoded:
      return {
        ...newItem,
        selectedRule: RuleType.Hardcoded,
        hardcoded: {
          value: item.value,
        },
      };
    case RuleType.Coalesce:
      return {
        ...newItem,
        selectedRule: RuleType.Coalesce,
        multipleColumns: item.sourceColNames,
        coalesce: {
          nullStrings: [],
        },
      };
    case RuleType.Concatenate:
      return {
        ...newItem,
        selectedRule: RuleType.Concatenate,
        multipleColumns: item.sourceColNames,
        concat: {
          separator: item?.separator ?? ", ",
        },
      };
    case RuleType.IgnoreConversion:
      return {
        ...newItem,
        column: item.sourceColumn,
        selectedRule: RuleType.IgnoreConversion,
        ignoreConversion: {
          allowEmpty: item.allowEmpty,
          allowNulls: item.allowNulls,
          valuesToIgnore: item.valuesToIgnore,
        },
      };
  }
}

const validateRequiredTargetCols = (
  state: { [x: string]: any; mappings?: any; advancedMappings?: any },
  data: IMappingSchema
) => {
  const required = new Set(
    data?.columns
      .filter((item: TargetMappingCol) => item.required)
      .map((item) => item.name)
  );

  const validItems: MappingRow[] = state.mappings.filter(
    (item: { valid: boolean }) => item.valid
  );
  const validAdvRules: AdvancedRow[] = state.advancedMappings.filter(
    (item: { valid: boolean }) => item.valid
  );

  const basicTargetCols = validItems.map((item) => item.mappedCol.name);
  const advTargetCols = validAdvRules.map((item) => item.mappedCol.name);
  const mappedTargetCols = new Set([basicTargetCols, advTargetCols].flat());

  return required.difference(mappedTargetCols);
};

export default function Mapping() {
  const { id: schemaId } = useParams();
  const { isShiAdmin } = useClaims();
  const [state, dispatch] = useReducer(rootReducer, initState);
  const [files, setFiles] = useState([]);
  const [uploadResponse, setUploadResponse] = useState<
    MappingRowsResultDto | undefined
  >(undefined);
  const [token, setToken] = useState(null);
  const [published, setPublished] = useState(false);
  const [description, setDescription] = useState("");
  const [jsonShowing, setJsonShowing] = useState(false);
  const [mappingSchemaId, setMappingSchemaId] = useState(-1);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [basicRowOpen, setBasicRowOpen] = useState(true);
  const [advancedRowOpen, setAdvancedRowOpen] = useState(false);
  const [schemaUrl, setSchemaUrl] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const PENDING = "Pending";

  const isInitialMount = useRef(true);

  const { data } = useMappingEngineSchema({
    url: schemaUrl,
  });

  const mutation = useMutation({
    mutationFn: async () => {
      const excelRowList = uploadResponse?.excelRowsList?.map((item) => {
        return JSON.stringify({
          columnName: item.columnName,
          sheetName: item.sheetName,
          exampleData: item.exampleData,
        });
      });

      const mappedValues = mappingValue(state, uploadResponse, published);

      return await api.createUpdateMapping(
        mappingSchemaId,
        Number.parseInt(schemaId!),
        1,
        description ?? "",
        mappedValues,
        published,
        uploadResponse?.guid,
        uploadResponse?.fileType,
        // @ts-ignore
        excelRowList,
        uploadResponse?.csvRowsList ?? []
      );
    },
    onSuccess: (updatedMapping: MappingDto) => {
      setMappingSchemaId(updatedMapping.id!);
      setIsSuccess(true);
      setError(false);
    },
    onError: () => {
      setIsSuccess(false);
      setError(true);
    },
  });

  useLayoutEffect(() => {
    if (isInitialMount.current) {
      api.getSchemaById(Number.parseInt(schemaId!)).then((schema) => {
        setSchemaUrl(schema.targetSchemaUrl!);
      });
      api
        .getMappingBySchemaId(Number.parseInt(schemaId!))
        .then((mappingDto) => {
          if (mappingDto) {
            setMappingSchemaId(mappingDto.id!);
            const jsonData = JSON.parse(mappingDto.mapping!);
            const mappingFileType = jsonData["fileType"];
            if (mappingFileType === "Excel") {
              const mappingExcelSheets = jsonData["excelSheets"];
              const mappings = jsonData["mappings"];
              const status = jsonData["status"];
              const advancedMappings = jsonData["advancedMappings"];
              const isPublished = status !== "Draft";

              setDescription(mappingDto?.description ?? "");
              setPublished(isPublished);
              const uploadResponse = mappingDto.uploadResponse!;
              setUploadResponse(uploadResponse);

              const newMappedExcelSheets: {
                type: excelSheetActions;
                locationKey: any;
                locationPattern: any;
                sourceSheetName: any;
                id: any;
                headerRow: number;
              }[] = [];
              mappingExcelSheets.forEach((item: ExcelSheet) => {
                const newExcelSheet = {
                  type: excelSheetActions.populate_excel,
                  locationKey: item.locationKey,
                  locationPattern: item.locationPattern,
                  sourceSheetName: item.sourceSheetName,
                  id: uuidv4(),
                  headerRow: item.headerRow!,
                };

                newMappedExcelSheets.push(newExcelSheet);
                dispatch(newExcelSheet);
              });

              mappings.forEach((item: { [key: string]: any }) => {
                const excelSheet = newMappedExcelSheets?.filter(
                  (sheet) => sheet.sourceSheetName === item?.sourceSheetName
                );
                const newItem = {
                  type: mappingActions.populate_mapping,
                  mappingColName: item.targetColumn,
                  mappingColType: item.targetColumnType,
                  sourceColDateFormat: item?.sourceColDateFormat,
                  column: item.sourceColumn,
                  naOverride: item.naOverride,
                  excelSheetLocationKey: item.locationKey,
                  excelSourceSheetName: excelSheet[0]?.sourceSheetName,
                  excelSheetId: excelSheet[0]?.id,
                  excelSheetPattern: excelSheet[0]?.locationPattern,
                };
                dispatch(newItem);
              });

              advancedMappings?.forEach((item: { [key: string]: any }) => {
                const excelSheet = newMappedExcelSheets?.filter(
                  (sheet) => sheet.sourceSheetName === item?.sourceSheetName
                );

                const newItem = {
                  type: advActions.populate_adv_row,
                  mappingColName: item.targetColumn,
                  mappingColType: item.targetColumnType,
                  sourceColDateFormat: item?.sourceColDateFormat,
                  excelSheetLocationKey: item.locationKey,
                  excelSourceSheetName: excelSheet[0]?.sourceSheetName,
                  excelSheetId: excelSheet[0]?.id,
                  excelSheetPattern: excelSheet[0]?.locationPattern,
                };
                dispatch(handleAdvSetup(item, newItem));
              });
            }
          }
        });
      isInitialMount.current = false;
    }
  }, [schemaId]);

  useEffect(() => {
    (async () => {
      // @ts-ignore
      setToken(await GetBearerToken());
    })();
  }, []);

  if (!isShiAdmin()) {
    return null;
  }
  const handleDesc = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDescription(e.target.value);
  };

  const setupGroupedSheets = (mapping: MappingRow[]) => {
    const map = new Map<string, MappingRow[]>([[PENDING, []]]);

    mapping.forEach((mapping) => {
      if (!mapping.valid) {
        const currentMapItem = map.get(PENDING);
        currentMapItem?.push(mapping);
      } else {
        if (!map.has(mapping.excelSheet.locationKey) && mapping.valid) {
          map.set(mapping.excelSheet.locationKey, [mapping]);
        } else {
          const currentMapItem = map.get(mapping.excelSheet.locationKey);
          currentMapItem?.push(mapping);
        }
      }
    });

    const result = [];

    for (const [key, value] of map.entries()) {
      if (key === PENDING) continue;

      if (value.length === 0) continue;
      result.push(
        <div key={key}>
          <Typography className="ml-3 py-4" variant="subtitle1">
            {key} ({value.length})
          </Typography>
          <Divider className="mb-4" />
          {value.map((mapping) => (
            <Row
              key={mapping.id}
              mapping={mapping}
              dispatch={dispatch}
              data={data}
              state={state}
              // @ts-ignore
              uploadResponse={uploadResponse}
            />
          ))}
        </div>
      );
    }

    const pendingMappings = map.get(PENDING);
    if (pendingMappings && pendingMappings.length > 0) {
      result.push(
        <div>
          <Typography className="ml-3 py-4" variant="subtitle1">
            {PENDING} ({pendingMappings.length})
          </Typography>
          <Divider className="mb-4" />
          {pendingMappings.map((mapping) => (
            <Row
              key={mapping.id}
              mapping={mapping}
              dispatch={dispatch}
              data={data}
              state={state}
              // @ts-ignore
              uploadResponse={uploadResponse}
            />
          ))}
        </div>
      );
    }

    return result;
  };

  const setupAdvGroupedSheets = (mapping: AdvancedRow[]) => {
    const map = new Map<string, AdvancedRow[]>([[PENDING, []]]);

    mapping.forEach((mapping) => {
      if (!mapping.valid || mapping.selectedRule === RuleType.Unknown) {
        const currentMapItem = map.get(PENDING);
        currentMapItem?.push(mapping);
      } else {
        if (!map.has(mapping.selectedRule)) {
          map.set(mapping.selectedRule, [mapping]);
        } else {
          const currentMapItem = map.get(mapping.selectedRule);
          currentMapItem?.push(mapping);
        }
      }
    });

    const result = [];

    for (const [key, value] of map.entries()) {
      if (key === PENDING) continue;

      result.push(
        <>
          <Typography className={"ml-3 py-4"} variant={"subtitle1"}>
            {key} ({value?.length})
          </Typography>
          <Divider className={"mb-4"} />
          {value.map((mapping) => (
            <AdvRule
              mapping={mapping}
              key={mapping.id}
              data={data}
              state={state}
              dispatch={dispatch}
              // @ts-ignore
              uploadResponse={uploadResponse}
            />
          ))}
        </>
      );
    }

    const pendingMappings = map.get(PENDING);
    if (pendingMappings && pendingMappings.length > 0) {
      result.push(
        <div>
          <Typography className="ml-3 py-4" variant="subtitle1">
            {PENDING} ({pendingMappings.length})
          </Typography>
          <Divider className="mb-4" />
          {pendingMappings.map((mapping) => (
            <AdvRule
              mapping={mapping}
              key={mapping.id}
              data={data}
              state={state}
              dispatch={dispatch}
              // @ts-ignore
              uploadResponse={uploadResponse}
            />
          ))}
        </div>
      );
    }

    return result;
  };

  const handleAdvRowAdd = () => {
    dispatch({ type: advActions.add_adv_row });
  };

  const handleBasicRowAdd = () => {
    dispatch({ type: mappingActions.add_mapping });
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <Snackbar
        open={isSuccess || error}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={1500}
        onClose={() => {
          setIsSuccess(false);
          setError(false);
        }}
      >
        <Alert
          severity={!error ? "success" : "error"}
          onClose={() => {
            setIsSuccess(false);
            setError(false);
          }}
        >
          {!error ? "Successfully updated mapping" : "Error updating mapping"}
        </Alert>
      </Snackbar>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <div className="flex justify-between">
        <div>
          <TextField
            label="Description"
            className="mr-4"
            onChange={handleDesc}
            value={description}
            size={"small"}
          />
          <FormControl className="min-w-32">
            <InputLabel id="version-label">Version</InputLabel>
            <Select
              fullWidth
              label="Version"
              variant="outlined"
              disabled
              defaultValue={1}
              labelId="version-label"
              size={"small"}
            >
              <MenuItem value={1}>1</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            className={"ml-2"}
            control={
              <Switch
                color={"secondary"}
                checked={published}
                onChange={() => setPublished(!published)}
                name="Published"
              />
            }
            label="Published"
          />
        </div>
        <div className={"flex gap-2"}>
          <Button
            variant={"outlined"}
            onClick={() => setJsonShowing(!jsonShowing)}
            startIcon={<NotesIcon />}
            color={"inherit"}
          >
            {jsonShowing ? "Hide JSON" : "Show Json"}
          </Button>
          <Button
            startIcon={<Save />}
            onClick={() => {
              const result = validateRequiredTargetCols(state, data!);
              if (result.size === 0) {
                mutation.mutate();
              } else {
                const resultArray = Array.from(result);
                setAlertMessage(
                  `ERROR The following required target columns must be mapped: ${resultArray.join(
                    ", "
                  )}`
                );
                setAlertOpen(true);
              }
            }}
            variant={"outlined"}
          >
            Save
          </Button>
        </div>
      </div>
      <FileUpload
        files={files}
        setFiles={setFiles}
        setUploadResponse={setUploadResponse}
        // @ts-ignore
        token={token}
      />
      {uploadResponse?.fileType === "Excel" && (
        <Card className="mt-4">
          <Typography variant="h6" className="text-center pt-4">
            Available Sheets
          </Typography>
          {state.excelSheets.map((sheet: ExcelSheet) => (
            <ExcelSheetRow
              key={sheet.id}
              sheet={sheet}
              dispatch={dispatch}
              uploadResponse={uploadResponse}
            />
          ))}
          <Button
            onClick={() => dispatch({ type: excelSheetActions.add_excel })}
            startIcon={<AddIcon />}
          >
            Add Sheet
          </Button>
        </Card>
      )}
      <div className="flex justify-between mt-4">
        <div className={"w-full"}>
          <Button
            className={"text-xl capitalize"}
            size={"large"}
            color={"inherit"}
            onClick={() => setBasicRowOpen(!basicRowOpen)}
            endIcon={basicRowOpen ? <ExpandLess /> : <ExpandMore />}
          >
            Basic ({state.mappings.length})
          </Button>
          <Collapse in={basicRowOpen}>
            <div className="mt-8 grow">
              {setupGroupedSheets(state.mappings)}
              {uploadResponse && (
                <Button onClick={handleBasicRowAdd} startIcon={<AddIcon />}>
                  Add Row
                </Button>
              )}
            </div>
          </Collapse>
        </div>
        <Drawer
          anchor={"right"}
          open={jsonShowing}
          onClose={() => setJsonShowing(false)}
          keepMounted
        >
          <JsonView
            state={state}
            // @ts-ignore
            uploadResponse={uploadResponse}
            isPublished={published}
          />
        </Drawer>
      </div>
      <Divider className={"mt-4"} />
      <div className={"mt-4"}>
        <Button
          className={"text-xl capitalize"}
          size={"large"}
          color={"inherit"}
          onClick={() => setAdvancedRowOpen(!advancedRowOpen)}
          endIcon={advancedRowOpen ? <ExpandLess /> : <ExpandMore />}
        >
          Advanced ({state.advancedMappings.length})
        </Button>
        <Collapse in={advancedRowOpen}>
          <div className="mt-8">
            {setupAdvGroupedSheets(state.advancedMappings)}
            <Button
              className={"self-start"}
              onClick={handleAdvRowAdd}
              startIcon={<AddIcon />}
            >
              Add Advance Row
            </Button>
          </div>
        </Collapse>
      </div>
    </>
  );
}
