import React, { memo, useState } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { locationPatterns } from "../../reducers/excelSheetReducer";
import { MappingRowsResultDto } from "@lib/ShiOneClient";

interface ExampleData {
  colName?: string;
  exampleData?: string[];
}

const MemoExcelColumnAutoComplete = memo(function ExcelColumnAutoComplete({
  item,
  uploadResponse,
  handleMappingChange,
  allowMultiple = false,
  className,
}: {
  item: any;
  uploadResponse: MappingRowsResultDto;
  handleMappingChange: any;
  allowMultiple?: boolean;
  className?: string;
}) {
  const options: ExampleData[] = [
    ...new Set(
      uploadResponse?.excelRowsList
        ?.filter((row) => {
          // Determine if the row matches the location pattern criteria
          if (item.excelSheet.locationPattern === locationPatterns.startsWith) {
            return row?.sheetName?.startsWith(item.excelSheet.locationKey);
          } else {
            return row?.sheetName === item.excelSheet.locationKey;
          }
        })
        .map((row) => ({
          colName: row.columnName,
          exampleData: row.exampleData,
        }))
    ),
  ];

  const [selectedOptions, setSelectedOptions] = useState<ExampleData[]>(() => {
    const result: ExampleData[] = [];
    item?.multipleColumns?.forEach((colName: string) => {
      const foundOp = options.find((op: ExampleData) => op.colName === colName);
      if (foundOp) {
        result.push(foundOp);
      }
    });

    return result;
  });

  const handleOptionChange = (
    _event: unknown,
    newValue: ExampleData | ExampleData[] | null
  ) => {
    if (allowMultiple && Array.isArray(newValue)) {
      setSelectedOptions(newValue);
      const updateValue = newValue.map((option: ExampleData) =>
        option?.colName?.trim()
      );
      handleMappingChange(
        {
          target: {
            name: "multipleColumns",
            value: updateValue,
          },
        },
        item.id
      );
    } else {
      if (newValue && "colName" in newValue) {
        handleMappingChange(
          {
            target: { name: "column", value: newValue?.colName || "" },
          },
          item.id
        );
      } else {
        handleMappingChange(
          {
            target: { name: "column", value: "" },
          },
          item.id
        );
      }
    }
  };

  const handleValue = () => {
    if (allowMultiple) {
      return selectedOptions;
    }
    return options.find((option) => option?.colName === item.column);
  };

  return (
    <Autocomplete
      className={className}
      fullWidth
      options={options}
      getOptionLabel={(option: ExampleData) => option.colName || ""}
      // @ts-ignore
      value={handleValue() ?? ""}
      onChange={handleOptionChange}
      multiple={allowMultiple}
      renderInput={(params) => (
        <TextField
          {...params}
          label={allowMultiple ? "Select column(s)" : "Select a column"}
          variant="outlined"
          disabled={item.naOverride}
        />
      )}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          const { key, ...otherProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              label={`(${index + 1}) ${option.colName}`}
              {...otherProps}
            />
          );
        });
      }}
      renderOption={(props, option) => {
        if (allowMultiple) {
          const isDisabled = item?.multipleColumns?.some(
            (selectedOption: string) => selectedOption === option.colName
          );
          const index = item?.multipleColumns?.findIndex(
            (selectedOption: string) => selectedOption === option.colName
          );

          return (
            <MenuItem disabled={isDisabled} {...props} key={option.colName}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <div>
                  {option.colName}{" "}
                  {allowMultiple && index && index !== -1
                    ? `(${index + 1})`
                    : ""}
                </div>
                {option.exampleData?.map((i, idx) => (
                  <Typography key={idx} variant="caption" color="textSecondary">
                    {i}
                  </Typography>
                ))}
              </Box>
            </MenuItem>
          );
        }

        return (
          <MenuItem {...props} key={option.colName}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <div>{option.colName} </div>
              {option.exampleData?.map((i, idx) => (
                <Typography key={idx} variant="caption" color="textSecondary">
                  {i}
                </Typography>
              ))}
            </Box>
          </MenuItem>
        );
      }}
    />
  );
});

export default MemoExcelColumnAutoComplete;
