import { AssessmentProgram } from "@lib/ShiOneClient";
import { ShiOnePermission } from "../../../../auth/authorizationTypes";

export const CACHE_KEY_ASSESSMENTS = "assessments";
export const CACHE_KEY_HARVEY_BALL = "assessments-harvey-ball";

export const CACHE_KEY_ASSESSMENTS_OVERVIEW = "assessments-overview";
export const CACHE_KEY_ASSESSMENTS_MATURITY_CHART =
  "assessments-maturity-chart";
export const CACHE_KEY_ASSESSMENTS_RECOMMENDATIONS = "assessments-recs";
export const CACHE_KEY_ASSESSMENTS_PLATFORM_INSIGHTS =
  "assessments-platform-insights";
export const CACHE_KEY_ASSESSMENTS_CAPABILITY_INSIGHTS =
  "assessments-capability-insights";

export const assessmentPrograms = {
  spr: "security-posture-review",
  aiReadiness: "ai-readiness",
};

export enum assessmentTabIds {
  overview = "overview",
  maturityChart = "maturity-chart",
  recommendations = "recommendations",
  platformInsights = "platform-insights",
  capabilityInsights = "capability-insights",
}

export enum assessmentTabViewMode {
  table = "Table View",
  card = "Card View",
  list = "List View",
}

export const sprCategories: { [key: string]: string } = {
  applications: "Applications",
  cloud: "Cloud",
  dataSecurity: "Data Security",
  endUser: "End User",
  governanceCompliance: "Governance & Compliance",
  identity: "Identity",
  monitoringAlerting: "Monitoring & Alerting",
  network: "Network",
  operations: "Operations",
};

export const aiReadiness: { [key: string]: string } = {
  infrastructure: "Infrastructure",
  business: "Business",
  data: "Data",
  security: "Security",
};

export const capabilitiesPriorities = {
  doNow: "Do Now",
  doLater: "Do Later",
  backlog: "Backlog",
  unknown: "Unknown",
};

export const capabilitiesImpact = {
  lowImpact: "Low Impact",
  highImpact: "High Impact",
};

export const sortedPriorities: { [key: string]: number } = {
  "Do Now": 1,
  "Do Later": 2,
  Backlog: 3,
  Unknown: 4,
};

export const maturityLevels: { [key: string]: string } = {
  optimized: "Optimized",
  operational: "Operational",
  functional: "Functional",
  evaluating: "Evaluating",
  gap: "Gap",
  unknown: "Unknown",
};

export const timePeriodPriority: Map<string, string> = new Map<string, string>([
  ["Do Now", "Within 12 months"],
  ["Do Later", "After 12 months"],
  ["Backlog", ""],
]);

export const assessmentPermissions = new Map<
  AssessmentProgram,
  ShiOnePermission
>([
  [AssessmentProgram.AiReadiness, ShiOnePermission.aiReadinessRead],
  [
    AssessmentProgram.SecurityPostureReview,
    ShiOnePermission.securityPostureReviewRead,
  ],
  [
    AssessmentProgram.DevOpsPostureReview,
    ShiOnePermission.devOpsPostureReviewRead,
  ],
]);
